import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { DeviceInstance } from './classes/DeviceInstance.class';
import { DevicesListViewComponent } from './views/devices-list-view/devices-list-view.component';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { RMA } from './classes/RMA.class';
import { RmaListViewComponent } from './views/rma-list-view/rma-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  exports: [DevicesListViewComponent, RmaListViewComponent],
  entryComponents: [DevicesListViewComponent, RmaListViewComponent],
  declarations: [DevicesListViewComponent, RmaListViewComponent],
})
export class DevicesModule extends ModuleBase {
  public static moduleName = 'invoicing';

  public ngOnInit() {
    ClassManager.registerClass('DeviceInstance', DeviceInstance);
    ClassManager.registerClass('RMA', RMA);

    HomepageViewComponent.registerGroups({
      devices: {
        name: 'Matériels et RMA',
        backColor: 'rgb(162, 199, 12)',
        textColor: 'black',
        permission: 'home_groupe_materiels_rma',
        children: [
          {
            name: 'Liste des matériels',
            permission: 'home_bouton_materiels_liste',
            component: DevicesListViewComponent,
          },
          ...(config.interop.lists.devices
            ? [
                {
                  name: 'Liste des matériels 🏎️',
                  permission: 'home_bouton_materiels_liste',
                  href: '/devices',
                },
              ]
            : []),
          {
            name: 'Liste des RMA',
            permission: 'home_bouton_materiels_rma',
            component: RmaListViewComponent,
          },
          ...(config.interop.lists.devices
            ? [
                {
                  name: 'Liste des RMA 🏎️',
                  permission: 'home_bouton_materiels_rma',
                  href: '/rmas',
                },
              ]
            : []),
        ],
      },
    });

    return super.ngOnInit();
  }
}
