import { Component, ElementRef, ViewChild } from '@angular/core';

import { Toolbar } from '../../components/toolbar/classes/Toolbar.class';
import { ViewBaseComponent } from '../../components/views/view-base.component';

@Component({
  selector: 'app-interop-view',
  templateUrl: './interop-view.component.html',
  styleUrls: ['./interop-view.component.scss'],
})
export class InteropViewComponent extends ViewBaseComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    data: this,
  };

  @ViewChild('interopContainer') interopContainer: ElementRef<HTMLDivElement>;

  componentName = '';
  route: string | undefined = undefined;
  interopKey = Math.random() * 1000000;
  props = {};

  initView(componentName, toolbarComponentName, props): void {
    console.log('[Interop] init interop view for', componentName, props);
    this.componentName = componentName;
    this.toolbar.interopComponentName = toolbarComponentName;
    this.props = props;
  }

  async ngAfterViewInit() {
    console.log('[Interop] ngAfterViewInit, injecting interop...', this.props);
    await this.waitForReactInterop();
    window.reactInterop.injectComponent(
      `interop-${this.interopKey}`,
      this.interopContainer.nativeElement,
      this.componentName,
      this.props || {}
    );
  }

  waitForReactInterop() {
    return new Promise<boolean>((resolve) => {
      var interval = setInterval(() => {
        if (
          window.reactInterop &&
          typeof window.reactInterop.injectComponent === 'function'
        ) {
          clearInterval(interval);
          resolve(true);
        }
      }, 500);
    });
  }
}
