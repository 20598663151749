import { Accounting } from './classes/Accounting.class';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { Country } from './classes/Country.class';
import { Currency } from './classes/Currency.class';
import { CustomerReference } from './classes/CustomerReference.class';
import { CustomersNumbering } from './classes/CustomersNumbering.class';
import { Deadline } from './classes/Deadline.class';
import { Delivery } from './classes/Delivery.class';
import { DeliveryTime } from './classes/DeliveryTime.class';
import { Discount } from './classes/Discount.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { LabelName } from './classes/LabelName.class';
import { Lang } from './classes/Lang.class';
import { Merchant } from './classes/Merchant.class';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { OtherDataViewComponent } from './views/other-data-view/other-data-view.component';
import { Postcode } from './classes/Postcode.class';
import { RollingDirection } from './classes/RollingDirection.class';
import { SuppliersNumbering } from './classes/SuppliersNumbering.class';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, ComponentsModule, TabsModule.forRoot()],
  declarations: [OtherDataViewComponent],
  entryComponents: [OtherDataViewComponent],
})
export class OtherDataModule extends ModuleBase {
  public static moduleName = 'other-data';

  public ngOnInit() {
    ClassManager.registerClass('Country', Country);
    ClassManager.registerClass('CustomerReference', CustomerReference);
    ClassManager.registerClass('CustomersNumbering', CustomersNumbering);
    ClassManager.registerClass('Deadline', Deadline);
    ClassManager.registerClass('Delivery', Delivery);
    ClassManager.registerClass('DeliveryTime', DeliveryTime);
    ClassManager.registerClass('Discount', Discount);
    ClassManager.registerClass('LabelName', LabelName);
    ClassManager.registerClass('Lang', Lang);
    ClassManager.registerClass('Merchant', Merchant);
    ClassManager.registerClass('Postcode', Postcode);
    ClassManager.registerClass('RollingDirection', RollingDirection);
    ClassManager.registerClass('SuppliersNumbering', SuppliersNumbering);
    ClassManager.registerClass('Accounting', Accounting);
    ClassManager.registerClass('Currency', Currency);

    HomepageViewComponent.registerGroups({
      database: {
        name: 'Base de données',
        permission: 'home_groupe_basededonnees',
        children: [
          {
            name: 'Autres données',
            component: OtherDataViewComponent,
            permission: 'home_bouton_basededonnees_autres',
          },
          ...(config.interop.lists.articles
            ? [
                {
                  name: 'Autres données 🚕',
                  permission: 'home_bouton_basededonnees_autres',
                  href: '/other-data',
                },
              ]
            : []),
        ],
      },
    });

    return super.ngOnInit();
  }
}
