import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Reimbursement } from '../../classes/Reimbursement.class';
import { ReimbursementViewComponent } from '../reimbursement-view/reimbursement-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-reimbursements-list-view',
  templateUrl: './reimbursements-list-view.component.html',
  styleUrls: ['./reimbursements-list-view.component.css'],
})
export class ReimbursementsListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_facturation_livrenc';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'N.C. commerciales',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer une<br/>note de crédit',
        icon: 'euro-sign',
        click: function (view: ReimbursementsListViewComponent) {
          view.createReimbursement();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: ReimbursementsListViewComponent) {
          view.deleteReimbursements();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public reimbursements: Reimbursement[] = [];
  public reimbursementsGridColumns = [
    { title: 'Numéro de la N.C.', field: 'number', width: 200 },
    { title: 'Numéro de facture', field: 'invoice.number', width: 200 },
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 150 },
    { title: 'Numéro de commande', field: 'order.number', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Désignation', field: 'designation', width: 450 },
    { title: 'Quantité\net unité', field: 'quantityAndUnit', width: 150 },
    {
      title: 'Prix\nde vente\nunitaire',
      field: 'converted_sell_price',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      negativeColor: 'red',
      width: 120,
    },
    {
      title: 'N° de commande\ndu client',
      field: 'order.customer_reference',
      width: 170,
    },
    {
      title: 'Date de\nlivraison',
      field: 'invoice.delivery_date',
      type: 'text',
      width: 95,
    },
    { title: 'Conditions\nde paiement', field: 'deadline', width: 150 },
    {
      title: 'Total\nacheté\nH.T.V.A.',
      field: 'converted_total_buyPrice',
      type: 'number',
      currencyField: 'buy_currency',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    {
      title: 'Total\ncrédité\nH.T.V.A.',
      field: 'converted_total_sellPrice',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    {
      title: 'Marge\nbrute',
      field: 'total_rawMargin',
      type: 'number',
      unit: '€',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    {
      title: '% marge\nbrute',
      field: 'total_rawMarginPerc',
      type: 'number',
      unit: '%',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    {
      title: 'Marge\nnette',
      field: 'total_netMargin',
      type: 'number',
      unit: '€',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    {
      title: '% marge\nnette',
      field: 'total_netMarginPerc',
      type: 'number',
      unit: '%',
      decimalsCount: 2,
      negativeColor: 'red',
      width: 100,
    },
    //{ title: 'Total T.V.A.C.', field: 'totalWithTax', type: 'number', unit: '€', decimalsCount: 5 }
  ];

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.reimbursementDblClick(event, item);
      },
    },
  ];

  public logisticsView: boolean = false;
  initView(logisticsView: boolean = false) {
    this.logisticsView = logisticsView;
    if (logisticsView) {
      this.toolbar.viewTitle = 'N.C. logistiques';
    }
  }

  ngOnInit() {
    this.reloadReimbursements();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadReimbursements();
  }

  reloadReimbursements() {
    Reimbursement.load(
      null,
      ['~date'],
      null,
      false,
      (AccountingsService.currentAccounting
        ? 'id_accounting=' + AccountingsService.currentAccounting.id + ' AND '
        : '') +
        'number LIKE "__/__' +
        (this.logisticsView ? '1' : '0') +
        '%"'
    ).then(
      (result) => {
        console.log('reimbursements:', result);
        this.reimbursements = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createReimbursement() {
    let reimbursement: Reimbursement = new Reimbursement();
    reimbursement.accounting = AccountingsService.currentAccounting;
    reimbursement.date = DateTimeUtil.toDateString(new Date());
    this.reimbursements.push(reimbursement);
    ViewsComponent.openView(ReimbursementViewComponent, reimbursement);
  }

  deleteReimbursements() {
    let reimbursements: Reimbursement[] = this.grid
      .selectedItems as Reimbursement[];
    if (Array.isArray(reimbursements) && reimbursements.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < reimbursements.length; ++i)
        promises.push(reimbursements[i].delete());
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Factures supprimées',
            summary: 'Les factures de prix ont été supprimées avec succès',
          });
          ArrayUtil.removeElements(this.reimbursements, reimbursements);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: "Une erreur s'est produite lors de la suppression",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  reimbursementDblClick(event, reimbursement) {
    ViewsComponent.openView(
      ReimbursementViewComponent,
      reimbursement,
      this.logisticsView
    );
  }
}
