// Services
import { RouterModule, Routes } from '@angular/router';

// Components
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// Modules
import { ModulesModule } from './modules/modules.module';
import { NgModule } from '@angular/core';
import { ServicesModule } from './services/services.module';
import { ViewsComponent } from './components/views/views.component';

const appRoutes: Routes = [{ path: '**', component: ViewsComponent }];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ModulesModule,
    ServicesModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent],
  //   entryComponents: [CustomersListViewComponent]
})
export class AppModule {}
