<ul class="debug-flags" *ngIf="debugMode">
  <li class="debug">DEBUG MODE</li>
</ul>

<div class="main-wrapper">
  <router-outlet *ngIf="ready"></router-outlet>

  <app-print-preview [global]="true"></app-print-preview>

  <app-settings></app-settings>
  <app-notifications #notifications></app-notifications>
  <app-notes></app-notes>
  <app-dialogs></app-dialogs>
  <app-loading></app-loading>
  <app-chars-table></app-chars-table>
</div>
