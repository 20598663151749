import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ApiService } from '../../../../services/api/api.service';
import { CookieService } from 'ngx-cookie-service';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { DataService } from 'src/app/services/data/data.service';
import { HomepageViewComponent } from '../../../homepage/views/homepage-view/homepage-view.component';
import { Merchant } from '../../../other-data/classes/Merchant.class';
import { User } from 'src/app/classes/credentials/User.class';
import { ViewBaseComponent } from '../../../../components/views/view-base.component';
import { ViewsComponent } from '../../../../components/views/views.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css'],
})
export class LoginViewComponent extends ViewBaseComponent {
  public message: string = "Connexion à l'ERP ...";
  public users: User[] = [];

  constructor(private cookieService: CookieService, protected ref: ChangeDetectorRef) {
    super(ref);
  }

  async ngOnInit() {
    this.cookieService.set('XDEBUG_SESSION', 'PHPSTORM');
    this.users = CredentialsService.users;
    let userToLogin: User = null;
    let sessionToken = '';

    try {
      // Try to auto login admin in DEV move
      if (!this.isProd) {
        console.log('DEV mode, automatic login...');
        userToLogin = (await User.load(['0:1']))[0];
        sessionToken = 'dev-session';
      }

      if (!userToLogin) {
        // Try to load user session
        let sessionId: string = this.cookieService.get('PHPSESSID');
        if (sessionId) {
          const sessionResult = await DataService.select({
            definition: {
              database: {
                table: 'user_sessions',
                id: 'id',
                db_id: null,
              },
              values: {
                session: { type: 'string' },
                ip: { type: 'string' },
                db_id_user: { type: 'string' },
                id_user: { type: 'string' },
              },
            },
            conditions: `session="${sessionId}"`,
          });

          if (sessionResult.result == 'success' && sessionResult.details.length > 0) {
            const sessionToken = sessionResult.details[0]['session'];
            const db_id_user: string = sessionResult.details[0]['db_id_user'];
            const id_user: string = sessionResult.details[0]['id_user'];
            if (db_id_user && id_user) {
              userToLogin = (await User.load([`${db_id_user}:${id_user}`]))[0];
            }
          }
        }
      }

      if (userToLogin) {
        this.message = "Connecté.<br/>Veuillez patienter pendant le chargement de l'ERP...";
        CredentialsService.sessionToken = sessionToken;
        await CredentialsService.login(userToLogin);
        ViewsComponent.openView(HomepageViewComponent);
      } else {
        window.location.href = 'login.php';
      }
    } catch (err) {
      this.message =
        'Erreur lors de la connexion à l\'ERP.<br/><a href="logout.php" class="btn btn-primary">Réessayer</a>';
      document.location.href = 'logout.php';
      console.error(err);
    }
  }
  public get isProd() {
    // return false;
    return environment.production === true;
  }
}
